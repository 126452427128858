import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import get from "lodash/get";
import Helmet from "react-helmet";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import ReactFitText from "react-fittext";
import "../styling/index.css";

const siteTitle = get(this, "props.data.site.siteMetadata.title");
const siteDescription = get(this, "props.data.site.siteMetadata.description");

export default props => (
  <div>
    <Helmet
      htmlAttributes={{ lang: "en" }}
      meta={[{ name: "description", content: siteDescription }]}
      title={siteTitle}
    />
    <Img
      fluid={props.data.background.childImageSharp.fluid}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: "-1"
      }}
    />
    <div className="big-container">
      <div className="container">
        <div className="header" />
        <div className="hero-text">
          <ReactFitText
            compressor={1}
            maxFontSize={66}
            minFontSize={48}
          >
            <h1>R o a s t N o t e s.</h1>
          </ReactFitText>
          <ReactFitText
            compressor={3.5}
            maxFontSize={20}
            minFontSize={16}
          >
            <p>
              A mobile app purpose built for discovering and sharing wonderful
              coffee.
            </p>
          </ReactFitText>
          <ReactFitText
            compressor={3.5}
            maxFontSize={20}
            minFontSize={16}
          >
            <p>Stay tuned for something great</p>
          </ReactFitText>
        </div>
        <div className="social">
          <a
            href="https://www.instagram.com/roastnotes/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/RoastNotes-581253715666676/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    background: file(relativePath: { eq: "wallpaper-coffee-on-table.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
